module.exports={
  "name": "iota.lib.js",
  "version": "0.4.8",
  "description": "Javascript Library for IOTA",
  "main": "./lib/iota.js",
  "scripts": {
    "build": "gulp",
    "test": "mocha"
  },
  "author": {
    "name": "Dominik Schiener (IOTA Foundation)",
    "website": "https://iota.org"
  },
  "keywords": [
    "iota",
    "tangle",
    "library",
    "browser",
    "javascript",
    "nodejs",
    "API"
  ],
  "license": "MIT",
  "bugs": {
    "url": "https://github.com/iotaledger/iota.lib.js/issues"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/iotaledger/iota.lib.js.git"
  },
  "dependencies": {
    "async": "^2.5.0",
    "bignumber.js": "^4.1.0",
    "crypto-js": "^3.1.9-1",
    "xmlhttprequest": "^1.8.0"
  },
  "devDependencies": {
    "bower": ">=1.8.0",
    "browserify": ">=14.1.0",
    "chai": "^4.0.2",
    "del": "^3.0.0",
    "gulp": "^4.0.0",
    "gulp-buffer": "0.0.2",
    "gulp-jshint": "^2.0.2",
    "gulp-nsp": "^3.0.1",
    "gulp-sourcemaps": "^2.6.4",
    "gulp-tap": "^1.0.1",
    "gulp-uglify": "^3.0.0",
    "jshint": "^2.9.4",
    "mocha": "^5.2.0"
  }
}
